import React from 'react'
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom'

import Blog from './Blog'
import Index from './Index'

const App = () => {
  const date = new Date()
  const year = date.getFullYear()
  return (
    <Router>
      <div className="d-flex flex-column h-100">
        <main className="flex-grow-1">
          <div className="container">
            <h1 className="mt-5" style={{color: '#365998'}}>
              <Link to="/">
                <b>Brian Myers</b>
              </Link>
            </h1>
            <Switch>
              <Route exact path="/blog">
                <Blog />
              </Route>
              <Route exact path="/">
                <Index />
              </Route>
            </Switch>
          </div>
        </main>

        <footer className="mt-auto py-3">
          <div className="container">
            <span className="text-muted">
              <b>&copy; 2019-{year} Brian Myers</b>. <br />
              All content on this site is available under a&nbsp;
              <a
                rel="license"
                href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution 4.0 International License
              </a>
              .
            </span>
          </div>
        </footer>
      </div>
    </Router>
  )
}

export default App
