import React from 'react'
import {Link} from 'react-router-dom'

const Index = () => {
  const links = {
    blog: <Link to="/blog">blog</Link>,
    education:
      "harvard (bachelor's in economics cum laude), insead (mba), sciences-po",
    email: <a href="mailto:brian.carl.myers@gmail.com">brian.carl.myers@gmail.com</a>,
    github: <a href="https://www.github.com/bcmyers">github.com/bcmyers</a>,
    hobbies:
      'running, math, physics, philosophy, value investing, programming, politics',
    languages: 'english, french',
    linkedin: (
      <a href="https://www.linkedin.com/in/bcmyers">linkedin.com/in/bcmyers</a>
    ),
    location: 'new york, ny',
    pgp: <a href="/pubkey.asc">public key</a>,
    'programming languages': 'rust, typescript/javascript, python, c, go',
    'sports teams':
      'kansas city chiefs, kansas city royals, sporting kc, psg, missouri tigers',
    twitter: <a href="https://twitter.com/bcmyers">@bcmyers</a>,
    youtube: (
      <a href="https://www.youtube.com/channel/UCXcHzKR8zfsq8hPjnSQ6DPw">
        youtube.com/channel/UCXcHzKR8zfsq8hPjnSQ6DPw
      </a>
    ),
  }

  return (
    <>
      <p className="lead">personal website</p>
      <br />
      <table className="table">
        <tbody>
          {Object.entries(links).map(([key, value], i) => {
            return (
              <tr key={i}>
                <th scope="row">{key}</th>
                <td>{value}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <br />
    </>
  )
}

export default Index
