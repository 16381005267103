import React from 'react'

const Blog = () => {
  return (
    <>
      <p className="lead">blog</p>
      <br />
      <p>Coming soon.</p>
    </>
  )
}

export default Blog
